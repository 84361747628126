import React, { useContext, useState, useCallback, useEffect } from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import axios from 'axios';
import Swal from 'sweetalert2'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
const config = require('../config.json')

export default function Email({ ipAddress }) {
    const history = useHistory();
    const [lid, setSplid] = useState('')
    const [code, setCode] = useState('')

    const handleSlid = (e) => {
        e.preventDefault()
        checkSplid(lid, code);
    }
    function checkSplid(slid, code) {
        if (slid.length > 4 && ipAddress.length > 6) {
            let data = JSON.stringify({
                "lid": slid,
                "code"  : code
            });

            let axiosConfig = {
                method: 'post',
                url: `${config.baseUrl}/api/verefi`,
                headers: {
                    'ip': ipAddress,
                    'Content-Type': 'application/json'
                },
                data: data
            };
            //   console.log(axiosConfig)
            axios.request(axiosConfig)
                .then((response) => {
                    console.log(response);
                    let res = (response.data);
                    
                    if (res.status) {
                        Swal.fire({
                            icon: 'info',
                            title: 'LinkDao Defi',
                            text: res.message
                        })
                    }
                    else {
                        Swal.fire({
                            icon: 'warning',
                            title: 'LinkDao Defi',
                            text: res.message
                        })
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }

    useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search)
        const lid = queryParameters.get("l");
        const code = queryParameters.get("code");
        if (lid) {
            setSplid(lid)
            setCode(code);
        }
    })
    return (
        <div className="container-xxl">
            <div className="authentication-wrapper authentication-basic container-p-y">
                <div className="authentication-inner py-4">
                    <div className="card">
                        <div className="card-body">
                            <div className="app-brand justify-content-center">
                                <Link to="/" className="app-brand-link gap-2">
                                    <span className="app-brand-logo demo">
                                        <img src="assets/ficon.svg" style={{ height: '40px', width: 'auto' }} alt="ficon" />
                                    </span>
                                    <span className="app-brand-text demo text-body fw-bolder" style={{ textTransform: "initial" }}>Link<span className='text-info'>Dao</span></span>
                                </Link>
                            </div>
                            
                            <h4 className="mb-2 text-info">Dear {lid} 🔒</h4>
                            
                            <p className="mb-4">Please Wait fro Email Verifaction</p>    
                            <p className="mb-4">Explore the multiple possibilites with our unique Eco-System</p>
                            {/* Connect with lkd form and action, go to dash.php */}
                            <br/>
                            <button className="btn btn-info d-grid w-100" onClick={handleSlid}>Click To Verify Your Email</button>
                            <br/>
                            <div className="text-center">
                                
                                {/* go back to login php */}
                                <Link to="/" className="d-flex align-items-center justify-content-center text-info">
                                    <i className="bx bx-chevron-left scaleX-n1-rtl bx-sm" />
                                    Back to login
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
