import React, { useContext, useCallback, useEffect, useState } from 'react'
import axios from 'axios';
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Swal from 'sweetalert2'
const config = require('../config.json')

export default function Login({ ipAddress, onLogin }) {
    const [lid, setlid] = useState('')
    const [pass, setpass] = useState('')
    // const history = useContext(HistoryContext)
    const history = useHistory();
    
    const handleLogin = async (event) => {
        event.preventDefault()
        try {
            login();
        } catch (err) {
            
        }
    }

    const login = async () => {

        let ip = ipAddress
        let data = JSON.stringify({
            "lid": lid,
            "password": pass,
            "ip": ip
        });
        let axiosConfig = {
            method: 'post',
            url: `${config.baseUrl}/api`,
            headers: {
                'ulid': lid,
                'ip': ip,
                'Content-Type': 'application/json'
            },
            data: data
        };
        console.log(axiosConfig)
        let response = await axios.request(axiosConfig)
        console.log(response)
        response = response.data
        if (response.status) {
            // console.log(response,data)
            let loginData = response.data
            loginData.address = response.data.address
            setLoginData(loginData)
            onLogin(response.data)
            history.push('/dash');
            
        }
        else if (response.code === 30) {
            Swal.fire({
                icon: 'info',
                title: 'LinkDao Defi',
                text: response?.message
            }).then(() => {
                history.push('/register');
            })
        }
        else if (response.code === 99) {
            Swal.fire({
                icon: 'info',
                title: 'LinkDao Defi',
                text: response?.message
            })
        }
    };
    function setLoginData(loginData) {
        sessionStorage.setItem('loginData', JSON.stringify(loginData));
    }
    return (
        <div className="container-xxl">
            <div className="authentication-wrapper authentication-basic container-p-y">
                <div className="authentication-inner py-4">
                    <div className="card">
                        <div className="card-body">
                            <div className="app-brand justify-content-center">
                                <Link to="/" className="app-brand-link gap-2">
                                    <span className="app-brand-logo demo">
                                        <img src="assets/ficon.svg" style={{ height: '40px', width: 'auto' }} alt='ficon' />
                                    </span>
                                    <span className="app-brand-text demo text-body fw-bolder" style={{ textTransform: "initial" }}>Link<span className='text-info'>Dao</span> </span>
                                </Link>
                            </div>
                            <h4 className="mb-2 text-info">Login 🔒</h4>
                            <p className="mb-4">Explore the multiple possibilites with our unique Eco-System.</p>
                            <form id="formAuthentication" className="mb-3" onSubmit={handleLogin}>
                            <div className="mb-3">
                                    <label htmlFor="1email" className="form-label">User ID</label>
                                    <input type="text" className="form-control" id="lid" name="lid" placeholder="Enter User ID" value={lid} onChange={(e) => setlid(e.target.value)} />
                                </div>
                                 <div className="mb-3">
                                    <label htmlFor="e1mail" className="form-label">Password</label>
                                    <input type="password" className="form-control" id="pass" name="pass" placeholder="Enter your Password" value={pass} onChange={(e) => setpass(e.target.value)} />
                                </div>
                                <button className="btn btn-info d-grid w-100" >Login</button>
                            </form>
                            <div className="text-center d-flex justify-content-center">
                                <Link to="/register" className="d-flex align-items-center justify-content-center text-info m-2"> Register Now</Link>
                                <Link to="/forgot" className="d-flex align-items-center justify-content-center text-warning m-2"> Forgot Password </Link>
                            </div>
                            <div className="text-center">
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
