import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { useContext, useState } from 'react';
import Login from "./pages/Login";
import Register from "./pages/Register";
import Dash from "./pages/Dash";
import Business from "./pages/Business";
import Downline from "./pages/Downline";
import Income from "./pages/Income";
import Profile from "./pages/Profile";
import Request from "./pages/Request";
import Support from "./pages/Support";
import Wallet from "./pages/Wallet";
import NotFound from './pages/NotFound';
import Ticket from './pages/Ticket';
import Performance from './pages/earning/perform';
import Top from './pages/earning/top';
import Passive from './pages/earning/passive';
import Vip from './pages/earning/vip';
import Star from './pages/earning/start';
import Award from './pages/earning/award';
import PrivateRoute from './pages/PrivateRoute';
import Leverage from './pages/leverage';
import Mentor from './pages/earning/mentor';
import Stake from './pages/Stakes/Stake';
import Email from './pages/Email';
import Forgot from './pages/forgot';

import Ewallet from './pages/wallet/Ewalle';
import EwatoEWOth from './pages/wallet/EwatoEwOth';
import EwToLw from './pages/wallet/EwToLw';
import LwToEw from './pages/wallet/LwToEw';
import LwToLew from './pages/wallet/LwToLew';
import RiwToEw from './pages/wallet/RiwToEw';
import Ewtoww from './pages/wallet/Ewtoww';
function App() {
  // const [ipAddress] = useContext(IpContext);
  const [ipAddress, setIpAddress] = useState("1.1.1.1");
  const [token, setToken] = useState('');
  const handleLogin = (token) => {
    setToken(token);
  };
  return (
    <BrowserRouter>
      
          <Switch>
            <Route exact path="/"><Login ipAddress={ipAddress} onLogin={handleLogin} /></Route>
            <Route path="/register"><Register ipAddress={ipAddress} /></Route>
            <Route path="/emailver"><Email ipAddress={ipAddress} /></Route>
            <Route path="/forgot"><Forgot ipAddress={ipAddress} /></Route>
            <PrivateRoute exact path="/dash" component={Dash} />
            <PrivateRoute exact path="/business" component={Business} />
            <PrivateRoute exact path="/downline" component={Downline} />
            <PrivateRoute exact path="/income" component={Income} />
            <PrivateRoute exact path="/profile" component={Profile} />
            <PrivateRoute exact path="/request" component={Request} />
            <PrivateRoute exact path="/wallet" component={Ewallet} />
            <PrivateRoute exact path="/support" component={Support} />
            <PrivateRoute exact path="/stake" component={Stake} />
            <PrivateRoute exact path="/ticket" component={Ticket} />
            <PrivateRoute exact path="/perform" component={Performance} />
            <PrivateRoute exact path="/top" component={Top} />
            <PrivateRoute exact path="/passive" component={Passive} />
            <PrivateRoute exact path="/vip" component={Vip} />
            <PrivateRoute exact path="/star" component={Star} />
            <PrivateRoute exact path="/award" component={Award} />
            <PrivateRoute exact path="/refule" component={Leverage} />
            <PrivateRoute exact path="/mentor" component={Mentor} />
            <PrivateRoute exact path="/vwallet" component={Ewallet} />

            <PrivateRoute exact path="/ewtoeoth" component={EwatoEWOth} />
            <PrivateRoute exact path="/ewtolev" component={EwToLw} />
            <PrivateRoute exact path="/lwroew" component={LwToEw} />
            <PrivateRoute exact path="/lwtolew" component={LwToLew} />
            <PrivateRoute exact path="/rwtoew" component={RiwToEw} />
            <PrivateRoute exact path="/ewtolww" component={Ewtoww} />
            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        
    </BrowserRouter>
  );
}

export default App;
