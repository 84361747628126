import React, { useState, useContext, useEffect, useCallback } from 'react'
import axios from 'axios';
import { getPrice, getBusdPrice, uploadStake } from "../../utils";
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Menu from '../../components/Menu';
import { LoadingContext } from '../../context/LoadingContext';
import ReactLoader from '../../components/ReactLoader';
import "./Stake.css";
import Swal from 'sweetalert2';
const config = require('../../config.json')


function Stake({ ipAddress, loginData }) {
  const [status, setStatus] = useState(false)
  const [account] = useState(loginData?.address)
  const [deposit, setDeposit] = useState(0)
  const [wallet, setWallet] = useState(0)
  const [daily, setDaily] = useState(0)
  const [profit, setProfit] = useState(0)
  const [price, setPrice] = useState(0)
  const [busdPrice, setBusdPrice] = useState(0)
  const [packages, setPackages] = useState([])
  const [loading, setLoading] = useContext(LoadingContext)
  

  const handleBusiness = useCallback(() => {
    setLoading(true)
    let data = JSON.stringify({
      "address": account,
      "ip": ipAddress,
      "ulid": loginData.ulid
    });
    
    let axiosConfig = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${config.baseUrl}/api/stakeinfo`,
      headers: { 
        'address': account, 
        'ip': ipAddress, 
        'ulid': loginData.ulid, 
        'auth': loginData.auth, 
        'token': loginData.token, 
        'Content-Type': 'application/json'
      },
      data : data
    };
    // console.log(axiosConfig)
    axios.request(axiosConfig)  
    .then((response) => {
      setPackages(response.data)
      console.log(response.data); 
      setLoading(false)
    })
    .catch((error) => {
      console.log(error);
    });
  },[account, ipAddress, loginData.auth, loginData.token, loginData.ulid, setLoading])

 
  const handlePrice = useCallback(async () => {
    let pr = await getPrice();
    setPrice(parseFloat(pr).toFixed(3));
  }, []);

  const handleBusdPrice = useCallback(async () => {
    let pr = await getBusdPrice();
    setBusdPrice(parseFloat(pr).toFixed(3));
  }, []);


  useEffect(() => {
    
    handlePrice();
    handleBusdPrice()
    handleBusiness()
  }, [account, handleBusdPrice, handleBusiness, handlePrice]);

  return (
    <div className="layout-container">
      <Menu />
      <div className="layout-page">
        <Header />
        {loading ? <><ReactLoader /></> :
          <div className="content-wrapper">
            <div className='container-xxl flex-grow-1 container-p-y'>
              <div className='row'>
                <div className="col-md-12  mb-3">
                  <div className="card">
                    <div className="card-header align-items-center ">
                      <div className="card-title mb-2">
                        <h6 className="m-0 me-2 text-center text-info">Stake Statistics</h6>
                      </div>
                      <div className="card-header d-flex align-items-center justify-content-between p-0">
                        <div className="card-title mb-0">
                          <h6 className="m-0 me-2">My Wallet</h6>
                        </div>
                        <div className="">
                          <p className="m-0 me-2">{wallet} LKD</p>
                          <small className="text-muted">$ {parseFloat(wallet * price).toFixed(3)}</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row'>
                
                {/* setting the card of pacakges 
                {packages.map(({ roiPercentage, totalReward, maxReward, startDate, totalInvestment, days,totalProfit }) => {
                  let plan = "", offer = ""
                  if (roiPercentage === 6) {
                    plan = "Basic Stake"
                    offer = "($25-$99)"
                    totalReward = totalInvestment * days * 0.002
                  } if (roiPercentage === 8) {
                    plan = "Standard Stake"
                    offer = "($100-$199)"
                    totalReward = totalInvestment * days * 0.00265
                  } if (roiPercentage === 10) {
                    plan = "Super Stake"
                    offer = "($200-$499)"
                    totalReward = totalInvestment * days * 0.0033
                  } if (roiPercentage === 12) {
                    plan = "Premium Stake"
                    offer = "($500  & Above)"
                    totalReward = totalInvestment * days * 0.004
                  }
                  totalReward = totalReward < 200 ? totalReward : 200

                  totalInvestment = Math.ceil(totalInvestment)
                  // totalReward = totalReward.toFixed(4)
                  totalReward  = (totalProfit*price).toFixed(4)

                  return (<div key={startDate} className="col-md-6  mb-3">
                    <div key={startDate} className="card h-100">
                      <div className="card-header d-flex align-items-center justify-content-between">
                        <div className="card-title mb-0">
                          <h5 className="mb-2 me-2 text-info">{plan} <span className='text-light' style={{ fontSize: "14px" }}>{offer}</span></h5>
                          <small className="">{startDate}</small>
                        </div>

                      </div>
                      <div className="card-body">
                        <div className="d-flex flex-wrap gap-2  mt-2">
                          <div className="d-flex flex-column w-50 me-2">
                            <h6 className="text-nowrap d-block mb-2">Deposit</h6>
                            <p className="mb-0">{totalInvestment} USDT</p>

                          </div>
                          <div className="d-flex flex-column" style={{ textAlign: "right" }}>
                            <h6 className="text-nowrap d-block mb-2">Max Reward</h6>
                            <p className="mb-0" >{Math.ceil(maxReward)} USDT</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>)
                  // return <></>
                })}
                 end the card of pacakges */}
              </div>

            </div>
            <Footer />
          </div>}
      </div>
    </div>
  )
}


export default Stake