import React, { useCallback, useContext, useEffect, useState } from 'react'
import { MobileSidebarContext } from '../context/MobileSidebarContext';
import { getPrice, truncateAddress } from '../utils';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';




export default function Header() {
    
    
    const [price, setPrice] = useState(0);
    const [mobileOpen, setMobileOpen] = useContext(MobileSidebarContext)
    const [address, setaddress] = useState('');
    const [uid, setuid] = useState('');
    const handlePrice = useCallback(async () => {
        let pr = await getPrice();
        setPrice(pr);
    }, []);

    
    useEffect(() => {
        const loginData = JSON.parse(sessionStorage.getItem('loginData'));
            setaddress((loginData?.address) ? loginData?.address : '')
            setuid((loginData?.ulid) ? loginData?.ulid : '')
        handlePrice();
    }, [handlePrice]);
 
    

    return (
        <>
            <nav className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme" id="layout-navbar">
                <div className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0   d-xl-none ">
                    <span onClick={() => setMobileOpen(true)} className="nav-item nav-link px-0 me-xl-4 " style={{ zIndex: 9000 }}>
                        <i className="bx bx-menu bx-sm" />
                    </span >
                </div>
                <div className="navbar-nav align-items-center">
                    <div className="nav-item navbar-search-wrapper mb-0">
                        <span className="nav-item nav-link search-toggler px-0">
                            <span className="text-info text-sm" >Live Price <span style={{ color: 'white' }}>${price}</span> </span>
                        </span>
                    </div>
                </div>
                <div className="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
                    <ul className="navbar-nav flex-row align-items-center ms-auto">
                        {/* Style Switcher */}
                        <li className="nav-item me-2 me-xl-0">
                            <span className="nav-link style-switcher-toggle hide-arrow">
                                <i className="bx bx-sm" />
                            </span>
                        </li>
                        <li className="nav-item ms-1 me-xl-0">
                        <span className="btn btn-success text-nowrap btn-xs text-sm">
                               {uid}
                            </span>
                        </li>
                        <li className="nav-item ms-1 me-xl-0">
                            <span className="btn btn-info text-nowrap btn-xs text-sm">
                                {truncateAddress(address)}
                            </span>
                        </li>
                        <li className="nav-item dropdown-shortcuts navbar-dropdown dropdown me-0 me-xl-0">
                            <span className="nav-link dropdown-toggle hide-arrow" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                                <i className="bx bx-grid-alt bx-sm" />
                            </span>
                            <div className="dropdown-menu dropdown-menu-end py-0">
                                <div className="dropdown-menu-header border-bottom">
                                    <div className="dropdown-header d-flex align-items-center py-3">
                                        <h5 className="text-body mb-0 me-auto">Shortcuts</h5>
                                    </div>
                                </div>
                                <div className="dropdown-shortcuts-list scrollable-container">
                                    <div className="row row-bordered overflow-visible g-0">
                                        <div className="dropdown-shortcuts-item col">
                                            <span className="dropdown-shortcuts-icon bg-label-secondary rounded-circle mb-2">
                                                <i className="bx bx-calendar fs-4" />
                                            </span>
                                            <Link to="/profile" className="stretched-link"><small className="text-muted mb-0">Profile</small></Link>
                                            {/* <small className="text-muted mb-0">Profile</small> */}
                                        </div>
                                        <div className="dropdown-shortcuts-item col">
                                            <span className="dropdown-shortcuts-icon bg-label-secondary rounded-circle mb-2">
                                                <i className="bx bx-food-menu fs-4" />
                                            </span>
                                            <Link to="/business" className="stretched-link"><small className="text-muted mb-0">Business</small></Link>
                                            {/* <small className="text-muted mb-0">Business</small> */}
                                        </div>
                                    </div>
                                    <div className="row row-bordered overflow-visible g-0">
                                        <div className="dropdown-shortcuts-item col">
                                            <span className="dropdown-shortcuts-icon bg-label-secondary rounded-circle mb-2">
                                                <i className="bx bx-user fs-4" />
                                            </span>
                                            <Link to="/business" className="stretched-link"><small className="text-muted mb-0">Re-Buy Leverage</small></Link>
                                            {/* <small className="text-muted mb-0">Re-Buy Leverage</small> */}
                                        </div>
                                        <div className="dropdown-shortcuts-item col">
                                            <span className="dropdown-shortcuts-icon bg-label-secondary rounded-circle mb-2">
                                                <i className="bx bx-check-shield fs-4" />
                                            </span>
                                            <Link to="/wallet" className="stretched-link"><small className="text-muted mb-0">Fund Transfer</small></Link>
                                            {/* <small className="text-muted mb-0">Fund Transfer</small> */}
                                        </div>
                                    </div>
                                    <div className="row row-bordered overflow-visible g-0">
                                        <div className="dropdown-shortcuts-item col">
                                            <span className="dropdown-shortcuts-icon bg-label-secondary rounded-circle mb-2">
                                                <i className="bx bx-pie-chart-alt-2 fs-4" />
                                            </span>
                                            <Link to="/request" className="stretched-link"><small className="text-muted mb-0">Withdrawl</small></Link>
                                        </div>
                                        <div className="dropdown-shortcuts-item col">
                                            <span className="dropdown-shortcuts-icon bg-label-secondary rounded-circle mb-2">
                                                <i className="bx bx-cog fs-4" />
                                            </span>
                                            <Link to="/support" className="stretched-link">
                                                <small className="text-muted mb-0">Support</small></Link>

                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </li>
                        
                    </ul>
                </div>
            </nav>
        </>
    )
}
