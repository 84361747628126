import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Menu from '../../components/Menu';
import React, { useCallback, useContext, useEffect,useState } from 'react'
import axios from 'axios';
import Swal from 'sweetalert2'
const config = require('../../config.json')
export default function EwToLw({ipAddress, loginData}) {
    const [account] = useState(loginData?.address);
    const [wallet, setWallet] = useState({})
    const [amount, setAmount] = useState(0)
    
    const handleWallet = useCallback(() => {
    
    let data = JSON.stringify({
      "address": account,
      "ip": ipAddress,
      "ulid": loginData.ulid
    });
    
    let axiosConfig = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${config.baseUrl}/api/balance_ewa`,
      headers: { 
        'address': account, 
        'ip': ipAddress, 
        'ulid': loginData.ulid, 
        'auth': loginData.auth, 
        'token': loginData.token, 
        'Content-Type': 'application/json'
      },
      data : data
    };
    // console.log(axiosConfig)
    axios.request(axiosConfig)  
    .then((response) => {
         console.log(response.data)
        setWallet(response.data)
    })
    .catch((error) => {
      console.log(error);
    });
  },[account, ipAddress, loginData.auth, loginData.token, loginData.ulid])
  
  
  const handleTranetu = (e) => {
    if(amount <= wallet?.ewallet && amount >= 0)
    {
        let data = JSON.stringify({
            "address": account,
            "ip": ipAddress,
            "ulid": loginData.ulid,
            "amount" : amount,
          });
          
          let axiosConfig = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${config.baseUrl}/api/fundTranstoLeve`,
            headers: { 
              'address': account, 
              'ip': ipAddress, 
              'ulid': loginData.ulid, 
              'auth': loginData.auth, 
              'token': loginData.token, 
              'Content-Type': 'application/json'
            },
            data : data
          };
        //   console.log(axiosConfig)
          axios.request(axiosConfig)  
          .then((response) => {
            console.log(response.data)
            let res = response.data;
            if(res.status)
            {
                handleWallet();
                Swal.fire({
                    icon: 'info',
                    title: 'LinkDao Defi',
                    text: res?.message
                  });
            }
            else
            {
                Swal.fire({
                    icon: 'warning',
                    title: 'LinkDao Defi',
                    text: res?.message
                  });
            }
              setWallet(response.data)
          })
          .catch((error) => {
            console.log(error);
          });
    }
    else{
        Swal.fire({
            icon: 'warning',
            title: 'LinkDao Defi',
            text: 'Please Enter valid Amount for transfer'
          });
    }
    
  }
  
  useEffect(() => {
    handleWallet()
  },[handleWallet])

    return (
        <>
            <div className="layout-container">
                <Menu />
                <div className="layout-page">
                    <Header />
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y pt-2">
                            <div>
                                <div className='row'>
                                    <div className="col-md-12  mb-2">
                                        <div className="card">
                                        <div className="card-header align-items-center ">
                                            <div className="card-title mb-0">
                                            <h6 className="m-0 me-2 text-center text-info">Fund Transfer To Leverage Wallet</h6>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="row">
                                    
                                    <div className="col-lg-12 col-md-12 col-sm-12 mb-2">
                                        <div className="card">
                                            <div className="card-body dashinc">
                                                <div className="d-flex justify-content-between">
                                                    <div className="card-info">
                                                        <p className="card-text m-0 text-info text-sm">E-Wallet Balance</p>
                                                        <div className="d-flex align-items-end mb-2">
                                                            <small className="text-white "> $ {wallet?.ewallet}</small>
                                                        </div>
                                                        
                                                    </div>
                                                    <div className="card-icon">
                                                        <span className="badge bg-label-info rounded p-2">
                                                            {/* <i className="bx bx-user bx-sm" /> */}
                                                            <i className='bx bx-dollar-circle bx-sm'></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-md-12  mb-2">
                                    <div className="card ">
                                        <div className="card-body align-items-center p-3">
                                        <div className="d-flex align-items-center justify-content-between mt-2">
                                            <input type="text" className="form-control" placeholder="Enter Amount" onChange={(e)=>{setAmount(e.target.value)}} />
                                        </div>
                                            <div className='text-center mt-3'>
                                                <button className='btn  btn-info btn-sm' onClick={handleTranetu} >Transfer</button>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    { wallet?.trans?.map((list, index) => {
                                        return (
                                            <>
                                                <div className="col-md-4  mb-1" key={index}>
                                                    <div className="card h-100">
                                                        <div className="card-header align-items-center" style={{padding :"3% 5% 3% 5%"}}>
                                                            <div className="col-md-12">
                                                                <div className='row d-flex justify-content-between'>
                                                                    <div className='col-6 text-left'>
                                                                        <span className="text-white text-sm">Date</span>
                                                                    </div>
                                                                    <div className='col-6' style={{textAlignLast:"end"}}>
                                                                        <span className={'text-info text-sm'} style={{fontSize:"14px"}}>{list.transaction_date}</span>
                                                                    </div>
                                                                </div>
                                                                <div className='row d-flex justify-content-between'>
                                                                    <div className='col-6 text-left'>
                                                                        <small className="text-sm">Amount</small>
                                                                    </div>
                                                                    <div className='col-6' style={{textAlignLast:"end"}}>
                                                                        <small className="text-sm"> $ {parseFloat(list.transaction_amount).toFixed(3)} </small>
                                                                    </div>
                                                                </div>
                                                                <div className='row d-flex justify-content-between'>
                                                                    <div className='col-6 text-left'>
                                                                        <small className="text-sm">Details</small>
                                                                    </div>
                                                                    <div className='col-6' style={{textAlign:"end"}}>
                                                                        <small className="text-info text-sm"><span >{list.transaction_details}</span></small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })}
                                    
                                </div>
                                
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        </>
    );
}
