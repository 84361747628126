import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Menu from '../../components/Menu';
import React, { useCallback, useEffect,useState } from 'react'
import axios from 'axios';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Swal from 'sweetalert2'

const config = require('../../config.json')
export default function Ewallet({ipAddress, loginData}) {
    const [account] = useState(loginData?.address);
    const [wallet, setWallet] = useState({})
    const [amount, setAmount] = useState(0)
    const [uid, setUid] = useState('')
    const history = useHistory();
    const handleWallet = useCallback(() => {
    
    let data = JSON.stringify({
      "address": account,
      "ip": ipAddress,
      "ulid": loginData.ulid
    });
    
    let axiosConfig = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${config.baseUrl}/api/balance`,
      headers: { 
        'address': account, 
        'ip': ipAddress, 
        'ulid': loginData.ulid, 
        'auth': loginData.auth, 
        'token': loginData.token, 
        'Content-Type': 'application/json'
      },
      data : data
    };
    // console.log(axiosConfig)
    axios.request(axiosConfig)  
    .then((response) => {
         console.log(response.data)
        setWallet(response.data)
        
    })
    .catch((error) => {
      console.log(error);
    });
  },[account, ipAddress, loginData])
  
  useEffect(() => {
    
    handleWallet()
  },[handleWallet])

    return (
        <>
            <div className="layout-container">
                <Menu />
                <div className="layout-page">
                    <Header />
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y pt-2">
                            <div>
                                <div className='row'>
                                    <div className="col-md-12  mb-2">
                                        <div className="card">
                                        <div className="card-header align-items-center ">
                                            <div className="card-title mb-0">
                                            <h6 className="m-0 me-2 text-center text-info">Wallet Details</h6>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    
                                    <div className="col-lg-4 col-md-4 col-sm-6 mb-2">
                                        <div className="card">
                                            <div className="card-body dashinc">
                                                <div className="d-flex justify-content-between">
                                                    <div className="card-info">
                                                        <p className="card-text m-0 text-info text-sm">Referral Wallet Balance</p>
                                                        <div className="d-flex align-items-end mb-2">
                                                            <small className="text-white "> $ {wallet?.balance}</small>
                                                        </div>
                                                        
                                                    </div>
                                                    <div className="card-icon">
                                                        <span className="badge bg-label-info rounded p-2">
                                                            {/* <i className="bx bx-user bx-sm" /> */}
                                                            <i className='bx bx-dollar-circle bx-sm'></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-6 mb-2">
                                        <div className="card">
                                            <div className="card-body dashinc">
                                                <div className="d-flex justify-content-between">
                                                    <div className="card-info">
                                                        <p className="card-text m-0 text-info text-sm">Leverage Wallet Balance</p>
                                                        <div className="d-flex align-items-end mb-2">
                                                            <small className="text-white "> $ {wallet?.leverage}</small>
                                                        </div>
                                                        
                                                    </div>
                                                    <div className="card-icon">
                                                        <span className="badge bg-label-info rounded p-2">
                                                            {/* <i className="bx bx-user bx-sm" /> */}
                                                            <i className='bx bx-dollar-circle bx-sm'></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-6 mb-2">
                                        <div className="card">
                                            <div className="card-body dashinc">
                                                <div className="d-flex justify-content-between">
                                                    <div className="card-info">
                                                        <p className="card-text m-0 text-info text-sm">E-Wallet Balance</p>
                                                        <div className="d-flex align-items-end mb-2">
                                                            <small className="text-white "> $ {wallet?.ewallet}</small>
                                                        </div>
                                                        
                                                    </div>
                                                    <div className="card-icon">
                                                        <span className="badge bg-label-info rounded p-2">
                                                            {/* <i className="bx bx-user bx-sm" /> */}
                                                            <i className='bx bx-dollar-circle bx-sm'></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {(wallet?.is_roi === false )?<></>:
                                    <>
                                    <div className="col-lg-4 col-md-4 col-sm-6 mb-2">
                                        <div className="card">
                                            <div className="card-body dashinc">
                                                <div className="d-flex justify-content-between">
                                                    <div className="card-info">
                                                        <p className="card-text m-0 text-info text-sm">ROI-Wallet Balance</p>
                                                        <div className="d-flex align-items-end mb-2">
                                                            <small className="text-white "> $ {wallet?.roi}</small>
                                                        </div>
                                                        
                                                    </div>
                                                    <div className="card-icon">
                                                        <span className="badge bg-label-info rounded p-2">
                                                            <i className='bx bx-dollar-circle bx-sm'></i> 
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </>}
                                </div>
                                <div className='row'>
                                    <div className="col-md-12  mb-2">
                                        <div className="card">
                                        <div className="card-header align-items-center ">
                                            <div className="card-title mb-0">
                                            <h6 className="m-0 me-2 text-center text-info">Wallet Funds Transfer</h6>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    
                                    <div className="col-lg-4 col-md-4 col-sm-6 mb-2" onClick={() => history.push('/ewtoeoth')}>
                                        <div className="card">
                                            <div className="card-body dashinc">
                                                <div className="d-flex justify-content-between">
                                                    <div className="card-info">
                                                        <p className="card-text m-0 text-info text-sm">E-wallet to Other ID e-Wallet </p>
                                                        <div className="d-flex align-items-end mb-2">
                                                            <small className="text-white "> $ {wallet?.ewallet}</small>
                                                        </div>
                                                    </div>
                                                    <div className="card-icon">
                                                        <span className="badge bg-label-info rounded p-2">
                                                            {/* <i className="bx bx-user bx-sm" /> */}
                                                            <i className='bx bx-log-in-circle bx-sm'></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-6 mb-2" onClick={() => history.push('/ewtolev')}>
                                        <div className="card">
                                            <div className="card-body dashinc">
                                                <div className="d-flex justify-content-between">
                                                    <div className="card-info">
                                                        <p className="card-text m-0 text-info text-sm">E-wallet to Leverage </p>
                                                        <div className="d-flex align-items-end mb-2">
                                                            <small className="text-white "> $ {wallet?.ewallet}</small>
                                                        </div>
                                                    </div>
                                                    <div className="card-icon">
                                                        <span className="badge bg-label-info rounded p-2">
                                                            {/* <i className="bx bx-user bx-sm" /> */}
                                                            <i className='bx bx-log-in-circle bx-sm'></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-6 mb-2" onClick={() => history.push('/lwroew')}>
                                        <div className="card">
                                            <div className="card-body dashinc">
                                                <div className="d-flex justify-content-between">
                                                    <div className="card-info">
                                                        <p className="card-text m-0 text-info text-sm">Referral Wallet To E-wallet </p>
                                                        <div className="d-flex align-items-end mb-2">
                                                            <small className="text-white "> $ {wallet?.balance}</small>
                                                        </div>
                                                    </div>
                                                    <div className="card-icon">
                                                        <span className="badge bg-label-info rounded p-2">
                                                            {/* <i className="bx bx-user bx-sm" /> */}
                                                            <i className='bx bx-log-in-circle bx-sm'></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-6 mb-2" onClick={() => history.push('/lwtolew')}>
                                        <div className="card">
                                            <div className="card-body dashinc">
                                                <div className="d-flex justify-content-between">
                                                    <div className="card-info">
                                                        <p className="card-text m-0 text-info text-sm">Referral Wallet To Leverage wallet </p>
                                                        <div className="d-flex align-items-end mb-2">
                                                            <small className="text-white "> $ {wallet?.balance}</small>
                                                        </div>
                                                    </div>
                                                    <div className="card-icon">
                                                        <span className="badge bg-label-info rounded p-2">
                                                            {/* <i className="bx bx-user bx-sm" /> */}
                                                            <i className='bx bx-log-in-circle bx-sm'></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {(wallet?.is_roi === false )?<></>:
                                    <>
                                        <div className="col-lg-4 col-md-4 col-sm-6 mb-2" onClick={() => history.push('/rwtoew')}>
                                            <div className="card">
                                                <div className="card-body dashinc">
                                                    <div className="d-flex justify-content-between">
                                                        <div className="card-info">
                                                            <p className="card-text m-0 text-info text-sm">ROI Wallet To E-wallet </p>
                                                            <div className="d-flex align-items-end mb-2">
                                                                <small className="text-white "> $ {wallet?.roi}</small>
                                                            </div>
                                                        </div>
                                                        <div className="card-icon">
                                                            <span className="badge bg-label-info rounded p-2">
                                                                {/* <i className="bx bx-user bx-sm" /> */}
                                                                <i className='bx bx-log-in-circle bx-sm'></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>}
                                    {(wallet?.wid === false )?<></>:
                                    <>
                                        <div className="col-lg-4 col-md-4 col-sm-6 mb-2" onClick={() => history.push('/ewtolww')}>
                                            <div className="card">
                                                <div className="card-body dashinc">
                                                    <div className="d-flex justify-content-between">
                                                        <div className="card-info">
                                                            <p className="card-text m-0 text-info text-sm">E-Wallet To Refferal wallet </p>
                                                            <div className="d-flex align-items-end mb-2">
                                                                <small className="text-white "> $ {wallet?.ewallet}</small>
                                                            </div>
                                                        </div>
                                                        <div className="card-icon">
                                                            <span className="badge bg-label-info rounded p-2">
                                                                {/* <i className="bx bx-user bx-sm" /> */}
                                                                <i className='bx bx-log-in-circle bx-sm'></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>}
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        </>
    );
}
